import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import intl from 'services/intl'
import styled, { CSS_VARS, getFont } from 'services/style'
import { connect } from 'services/store'
import { ReactComponent as ArrowLeftIcon } from 'assets/svg/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/svg/arrow-right.svg'

const MainCSS = styled.section`
  position: relative;
  .hsr {
    max-width: 750px;
  }
  .hsr-item {
    display: inline-flex !important;
    align-items: center;
    height: 320px;
    background-color: #ccc;
  }
  .hsr-inner {
    padding-left: 120px;
  }
  .hsr-title {
    margin-bottom: 10px;
    color: #ffffff;
    ${getFont('RC', 400, 30, true)};
  }
  .hsr-description {
    max-width: 500px;
    margin-bottom: 10px;
    color: #ffffff;
    ${getFont('OS', 600, 13)};
    line-height: 1.4;
  }
  .hsr-links .fg-btn.isPrimary {
    margin-right: 10px;
  }
  .slick-arrow {
    z-index: 1;
  }
  .hsr-prev,
  .hsr-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 1;
  }
  .hsr-prev {
    left: 10px;
  }
  .hsr-next {
    right: 10px;
  }
  .hsr-prev-icon,
  .hsr-next-icon {
    width: 16px;
    height: 16px;
    fill: #ffffff;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-dots li button:before {
    color: #ffffff;
    opacity: 0.4;
  }
  .slick-dots li.slick-active button:before {
    top: 1px;
    font-size: 8px;
    opacity: 1;
  }
  .slick-dots {
    bottom: 30px;
  }
  .hrs-goEnrollment {
    visibility: ${({ hideEnrollmentLink }) => (hideEnrollmentLink ? 'hidden' : 'visible')};
  }

  @media (max-width: ${CSS_VARS.max_mobile_screen}) {
    .hsr-inner {
      max-width: 100%;
      padding: 40px 50px;
    }
    .hsr-item {
      height: 390px;
    }
    .hsr-description {
      margin-bottom: 20px;
    }
    .fg-btn.isPrimary,
    .fg-btn.isSecondary {
      width: 100%;
      text-align: center;
    }
    .fg-btn.isPrimary {
      margin: 0 0 10px;
    }
    .slick-dots {
      bottom: 10px;
    }
  }
`

const HomeSlider = ({ agreementTypes, hasAgreementRequest }) => {
  const PrevButton = ({ onClick }) => {
    return (
      <button onClick={onClick} className="hsr-prev">
        <ArrowLeftIcon className="hsr-prev-icon" />
      </button>
    )
  }
  const NextButton = ({ onClick }) => {
    return (
      <button onClick={onClick} className="hsr-next">
        <ArrowRightIcon className="hsr-next-icon" />
      </button>
    )
  }

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />
  }

  const getPrice = (agreementTypes, agreementCode) => {
    let prices = null

    for (let i in agreementTypes) {
      if (agreementTypes.hasOwnProperty(i)) {
        const result = agreementTypes[i].filter(item => item.code === agreementCode)

        if (result && result.length) {
          prices = result[0].prices.total
        }
      }
    }

    return prices
  }

  const sliderItems = [
    {
      title: intl`PROPRIÉTAIRE`,
      description: intl`Le terme propriétaire désigne la personne physique (ou morale) ayant reçu l'agrément des Commissaires de France Galop l'autorisant à faire courir un cheval sous ses couleurs, à faire les engagements et à percevoir les sommes gagnées.`,
      price: getPrice(agreementTypes, 'PCOU'),
      code: 'PCOU'
    },
    {
      title: intl`ASSOCIÉ`,
      description: intl`La propriété d'un cheval déclaré à l'entraînement ou l'exploitation de sa carrière de courses peut faire l'objet d'une association. Pour chaque cheval, objet d'une association, il doit être établi un contrat d'association qui doit être enregistré par France Galop.`,
      price: getPrice(agreementTypes, 'ASSO'),
      code: 'ASSO'
    },
    {
      title: intl`BAILLEUR`,
      description: intl`Un cheval déclaré à l'entraînement peut faire l'objet d'un contrat de location entre un ou plusieurs bailleurs et un ou plusieurs locataires. Pour chaque cheval, objet d'une location, il doit être établi un contrat de location qui doit être enregistré par France Galop.`,
      price: getPrice(agreementTypes, 'BAIL'),
      code: 'BAIL'
    },
    {
      title: intl`ENTRAÎNEUR PUBLIC`,
      description: intl`L'autorisation d'exercer en qualité d'entraîneur public permet à la personne qui en est titulaire d'entraîner des chevaux appartenant à des propriétaires différents.`,
      price: getPrice(agreementTypes, 'ENPU'),
      code: 'ENPU'
    },
    {
      title: intl`ENTRAÎNEUR PARTICULIER`,
      description: intl`L'autorisation d'exercer en qualité d'entraîneur particulier permet à la personne qui en est titulaire d'entraîner des chevaux appartenant à un propriétaire unique avec lequel elle est liée par un contrat de travail agréé par les Commissaires de France Galop. Les chevaux entraînés par un entraîneur particulier ne peuvent pas faire l'objet d'un contrat d'association.`,
      price: getPrice(agreementTypes, 'ENPA'),
      code: 'ENPA'
    },
    {
      title: intl`PERMIS D'ENTRAÎNER`,
      description: intl`Le permis d'entraîner permet au propriétaire qui en est titulaire d'entraîner un effectif maximum de cinq chevaux lui appartenant en totalité, deux de ces chevaux pouvant toutefois être en location en totalité. Cinq remplacements sont admis au cours d'une même année.`,
      price: getPrice(agreementTypes, 'ENPE'),
      code: 'ENPE'
    },
    {
      title: intl`AUTORISATION D'ELEVEUR-ENTRAÎNEUR`,
      description: intl`L'autorisation d'éleveur-entraîneur permet à l'éleveur qui en est titulaire, d'entraîner sur son exploitation d'élevage des chevaux dont lui ou son conjoint, partenaire du PACS ou concubin est l'éleveur et qui appartiennent en totalité ou appartiennent en totalité à son conjoint, partenaire du PACS ou concubin. Cinq des chevaux déclarés peuvent cependant ne pas être élevés par ce propriétaire ou par son conjoint, partenaire du PACS ou concubin à condition qu'ils soient leur entière propriété ou pour deux d'entre eux en location en totalité.`,
      price: getPrice(agreementTypes, 'AUEN'),
      code: 'AUEN'
    },
    {
      title: intl`ESPOIR`,
      description: intl`L'autorisation de monter en courses en tant qu' « espoir » permet à celui qui en est titulaire de participer aux courses-école (non publiques), dans les épreuves du programme annuel élaboré par l'AFASEC et sur un hippodrome dont les règles sont régies par le Code des Courses.`,
      price: getPrice(agreementTypes, 'ESPO'),
      code: 'ESPO'
    },
    {
      title: intl`APPRENTI / STAGIAIRE`,
      description: intl`L'agrément en qualité d'apprenti ou de stagiaire permet à son titulaire de monter en courses publiques, en France ou à l'étranger.`,
      price: getPrice(agreementTypes, 'APPR'),
      code: 'APPR'
    },
    {
      title: intl`JEUNE JOCKEY / JOCKEY`,
      description: intl`L'agrément en qualité de jeune jockey ou de jockey permet à son titulaire de monter en courses publiques, en France ou à l'étranger.`,
      price: getPrice(agreementTypes, 'JJOC'),
      code: 'JJOC'
    },
    {
      title: intl`CAVALIER`,
      description: intl`L'agrément en qualité de cavalier permet à son titulaire de monter en courses publiques, en France ou à l'étranger.`,
      price: getPrice(agreementTypes, 'CAVL'),
      code: 'CAVL'
    },
    {
      title: intl`GENTLEMAN-RIDER / CAVALIERE`,
      description: intl`L'agrément en qualité de gentleman-rider ou de cavalière permet à son titulaire de monter en courses publiques, en France ou à l'étranger, dans les épreuves qui leur sont réservées.`,
      price: getPrice(agreementTypes, 'GENR'),
      code: 'GENR'
    },
    {
      title: intl`ELEVEUR`,
      description: intl`L'agrément éleveur permet à la personne physique ou morale qui fait naître un cheval dont le nom figure en tant que naisseur dans les registres de Stud Book de percevoir des primes à l'élevage.`,
      price: getPrice(agreementTypes, 'ELEV'),
      code: 'ELEV'
    }
  ]

  return (
    <MainCSS hideEnrollmentLink={hasAgreementRequest}>
      <Slider {...settings} className="hsr">
        {sliderItems.map(item => {
          return (
            <div key={item.code} className="hsr-item">
              <div className="hsr-inner">
                <h1 className="hsr-title">{item.title}</h1>
                <p className="hsr-description">{item.description}</p>
                <div className="hsr-links">
                  <Link className="fg-btn isPrimary" to={`/agreements/${item.code}`}>
                    {intl`Détails`}
                  </Link>
                  {/*<Link className="fg-btn isSecondary hrs-goEnrollment" to="/enrollment">*/}
                  {/* Faire une demande de propriétaire couleurs */}
                  {/*</Link>*/}
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </MainCSS>
  )
}

const mapPropsToState = ({ app: { lists }, view: { agreements = null } }) => {
  return {
    hasAgreementRequest: agreements ? !!agreements.length : true,
    agreementTypes: lists ? lists.agreementTypes : null
  }
}

export default connect(mapPropsToState)(HomeSlider)
