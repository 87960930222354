import React from 'react'
import styled, { getFont } from 'services/style'

const MainCSS = styled.label`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #e6e6e6;
  border-radius: 100%;
  flex-shrink: 0;
  overflow: hidden;
  vertical-align: middle;
  .Radio-input {
    display: none;
    width: 100%;
    height: 100%;
  }
  .Radio-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 100%;
    z-index: 1;
  }
  .Radio-input:checked + .Radio-image {
    display: block;
    background-color: #448233;
    border: 5px solid #ffffff;
  }
  & + .Radio-label {
    margin: 0 15px 0 4px;
    color: #454545;
    ${getFont('OS', 600, 12)};
    vertical-align: middle;
  }
`

export default React.forwardRef((props, ref) => {
  return (
    <>
      <MainCSS className={`Radio ${props.className ? props.className : ''}`}>
        <input ref={ref} className="Radio-input" {...props} type="radio" />
        <div className="Radio-image" />
      </MainCSS>
      {props.label && (
        <label htmlFor={props.id} className="Radio-label">
          {props.label}
        </label>
      )}
    </>
  )
})
