import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { CSS_VARS, getFont } from 'services/style'
import { connect } from 'services/store'
import { setState } from 'services/store/actions'
import intl from 'services/intl'
import { Field, Form, LoaderInline } from 'components'

const MainCSS = styled.section`
  margin: 0 auto;
  padding: 40px;
  max-width: 280px;
  text-align: center;
  .lfm-title {
    margin-bottom: 40px;
    ${getFont('RC', 400, 16, true)};
  }
  .lfm-loginFailed {
    margin-bottom: 40px;
    color: ${CSS_VARS.color_error};
    ${getFont('RC', 400, 16)};
  }
  .lfm-lostPwd {
    display: inline-block;
    margin-bottom: 40px;
    color: #cdcdcd;
    ${getFont('OS', 600, 13)};
    cursor: pointer;
    text-decoration: underline;
  }
  .lfm-captcha {
    display: none;
    margin-bottom: 40px;
  }
  .fg-btn {
    margin-bottom: 15px;
    width: 145px;
  }
  .fg-label {
    padding-right: 0;
  }
  .fg-label + * {
    display: block;
    width: auto;
    vertical-align: initial;
  }
  .fg-inputText {
    width: 100%;
    border-color: #cdcdcd;
    border-width: 0 0 1px 0;
  }
  .fg-inputText:focus {
    outline-width: 0;
  }
  .lfm-warning {
    position: fixed;
    left: 50%;
    top: 50%;
    padding: 40px 30px 30px;
    background-color: #333333;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    line-height: 1.6em;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .lfm-warning-close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
`

const LoginForm = ({ isLoging, handleLogin, loginFailed }) => {
  const [isWarningHidden, setWarningHidden] = useState(true)
  const handleSubmit = ([identifier, password]) => {
    handleLogin(identifier.value, password.value)
  }

  return (
    <>
      <MainCSS>
        {!isWarningHidden && (
          <div className="lfm-warning">
            <button className="lfm-warning-close" onClick={() => setWarningHidden(true)}>
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
                width="18"
                height="18"
                viewBox="0 0 32 32"
              >
                <line x1="0" y1="0" x2="32" y2="32" strokeWidth="3" />
                <line x1="32" y1="0" x2="0" y2="32" strokeWidth="3" />
              </svg>
            </button>
            {intl`Ce mardi 15 décembre 2020 à partir de 15h00, pour des raisons de maintenance des infrastructures, il ne sera pas possible de choisir de casaque pour une demande d'autorisation à faire courir ou de faire référence à un compte déjà existant. L'ensemble du service sera à nouveau opérationnel dès le lendemain matin.`}
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <h1 className="lfm-title">{intl`Connexion`}</h1>
          {loginFailed && (
            <div className="lfm-loginFailed">{intl`L'identifiant ou le mot de passe est incorrect`}</div>
          )}
          <Field>
            <label className="fg-label" htmlFor="login">
              {intl`Identifiant`}
            </label>
            <input
              className="fg-inputText"
              type="text"
              id="login"
              name="login"
              data-required={true}
            />
          </Field>
          <Field>
            <label className="fg-label" htmlFor="password">
              {intl`Mot de passe`}
            </label>
            <input
              className="fg-inputText"
              name="password"
              type="password"
              id="password"
              data-required={true}
            />
          </Field>
          {!isLoging && (
            <button
              type="button"
              className="lfm-lostPwd"
              onClick={() => setState({ isPasswordModalActive: true })}
            >
              {intl`Mot de passe oublié`}
            </button>
          )}
          <div className="lfm-captcha">Captcha</div>
          <div className="lfm-bottom">
            {isLoging ? (
              <LoaderInline />
            ) : (
              <>
                <button className="fg-btn isPrimary">{intl`Se connecter`}</button>
                <Link to="/accountCreate" className="fg-btn isSecondary" href="/">
                  {intl`Créer un compte`}
                </Link>
              </>
            )}
          </div>
        </Form>
      </MainCSS>
    </>
  )
}

const mapPropsToState = ({ view: { isLoging, handleLogin, loginFailed } }) => {
  return {
    isLoging,
    handleLogin,
    loginFailed
  }
}

export default connect(mapPropsToState)(LoginForm)
