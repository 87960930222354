import React from 'react'
import styled, { getFont } from 'services/style'

const MainCSS = styled.label`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #e6e6e6;
  flex-shrink: 0;
  overflow: hidden;
  vertical-align: middle;
  .Checkbox-input {
    display: none;
    width: 100%;
    height: 100%;
  }
  .Checkbox-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 1;
  }
  .Checkbox-input:checked + .Checkbox-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Checkbox-input:checked + .Checkbox-image:before {
    content: '✓';
    color: #448233;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
  }
  & + .Checkbox-label {
    margin: 0 15px 0 4px;
    color: #454545;
    ${getFont('OS', 600, 12)};
    vertical-align: middle;
  }
`

export default React.forwardRef((props, ref) => {
  return (
    <>
      <MainCSS className={`Checkbox ${props.className ? props.className : ''}`}>
        <input
          ref={ref}
          className="Checkbox-input"
          {...props}
          type="checkbox"
          data-required-type="checkbox"
        />
        <div className="Checkbox-image" /> 
      </MainCSS>
      {props.label && (
        <label htmlFor={props.id} className="Checkbox-label">
          {props.label}
        </label>
      )}
    </>
  )
})
