import React from 'react'
import { Link } from 'react-router-dom'
import styled, { CSS_VARS, getFont } from 'services/style'
import utils from 'services/utils'
import { connect } from 'services/store'
import HeaderMenu from './HeaderMenu'
import DataProvider from '../services/DataProvider'

const MainCSS = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  background-color: #ffffff;
  border-bottom: 5px solid ${CSS_VARS.color_btn_primary};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 1;
  .Header-logo {
    display: inline-block;
    flex-basis: calc(50% + 45px);
    text-align: right;
  }
  .Header-logo-link {
    display: inline-block;
  }
  .Header-logo-picture {
    width: 90px;
    height: 47px;
  }
  .Header-language {
    display: inline-block;
    margin-right: 60px;
    cursor: pointer;
    ${getFont('OS', 600, 12)};
    text-decoration: underline;
  }
  @media (max-width: ${CSS_VARS.max_mobile_screen}) {
    .Header-language {
      margin-right: 20px;
    }
  }
`

const Header = ({ isAuthenticated, activeStep }) => {
  const language = utils.app.get('language', false)
  const handleChangeUILanguage = () => {
    utils.app.set('language', language === 'fr' ? 'en' : 'fr')

    if (isAuthenticated) {
      utils.app.set('hasLanguageChanged', true)
    }

    ;(async () => {
      if (isAuthenticated) {
        await DataProvider.getLists()
      }

      if (activeStep) {
        window.location.href = window.location.href.split('?')[0] + '?step=' + activeStep
      }

      window.location.reload()
    })()
  }

  return (
    <MainCSS>
      <div className="Header-logo">
        <Link className="Header-logo-link" to="/">
          <img
            className="Header-logo-picture"
            src="/images/logo/logo-fg.png"
            srcSet="/images/logo/logo-fg@2x.png 2x,             
        /images/logo/logo-fg@3x.png 3x"
            alt="France Galop"
          />
        </Link>
      </div>
      <div>
        {isAuthenticated && <HeaderMenu />}
        <button className="Header-language" onClick={handleChangeUILanguage}>
          {language === 'fr' ? 'EN' : 'FR'}
        </button>
      </div>
    </MainCSS>
  )
}

const mapPropsToState = ({ app: { isAuthenticated }, view: { activeStep } }) => {
  return {
    isAuthenticated,
    activeStep: activeStep || null
  }
}

export default connect(mapPropsToState)(Header)
