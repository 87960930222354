import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled, { CSS_VARS, getFont } from 'services/style'
import intl from 'services/intl'
import { ReactComponent as ArrowUpIcon } from 'assets/svg/arrow-up.svg'
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg'
import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg'

const MainCSS = styled.div`
  display: inline-block;
  vertical-align: middle;
  .hmu {
    position: relative;
    margin-right: 40px;
    text-align: left;
    z-index: 1;
  }
  .hmu-label {
    cursor: pointer;
    ${getFont('OS', 600, 12)};
  }
  .hmu-label.isActive {
    color: ${CSS_VARS.color_btn_primary};
  }
  .hmu-label.isActive .hmu-label-arrowUpIcon {
    display: inline-block;
    fill: ${CSS_VARS.color_btn_primary};
  }
  .hmu-label.isActive .hmu-label-arrowDownIcon {
    display: none;
  }
  .hmu-label-arrowUpIcon {
    display: none;
    fill: ${CSS_VARS.color_btn_primary};
  }
  .hmu-label-arrowUpIcon,
  .hmu-label-arrowDownIcon {
    margin-left: 10px;
    width: 8px;
    height: 8px;
  }
  .hmu-label-menuIcon {
    display: none;
    width: 32px;
    height: 32px;
    fill: #757575;
  }
  .hmu-list {
    position: absolute;
    right: 0;
    top: calc(100% + 30px);
    padding: 10px 30px;
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    text-align: right;
  }
  .hmu-link {
    display: block;
    margin: 20px 0;
    color: currentColor;
    ${getFont('OS', 600, 12)};
    text-decoration: none;
    &:hover {
      color: ${CSS_VARS.color_btn_primary};
    }
  }
  @media (max-width: ${CSS_VARS.max_mobile_screen}) {
    .hmu {
      margin-right: 10px;
    }
    .hmu-label-text,
    .hmu-label-arrowUpIcon,
    .hmu-label-arrowDownIcon {
      display: none !important;
    }
    .hmu-label-menuIcon {
      display: block;
    }
  }
`

const HeaderMenu = ({ context }) => {
  const [isMenuShown, toggleShowMenu] = useState(false)
  const handleToggleMenu = () => toggleShowMenu(() => !isMenuShown)
  // Do this function because it is impossible to stop propagation from react event to native event
  const handleClickNav = () => (context.customStopPropagation = true)

  useEffect(() => {
    const handleClick = () => {
      if (context.customStopPropagation) {
        context.customStopPropagation = false
        return
      }
      if (isMenuShown) {
        toggleShowMenu(false)
      }
    }

    window.addEventListener('click', handleClick, false)
    return () => {
      window.removeEventListener('click', handleClick, false)
    }
  }, [context, isMenuShown, toggleShowMenu])

  return (
    <MainCSS>
      <nav className="hmu" onClick={handleClickNav}>
        <button onClick={handleToggleMenu} className={`hmu-label ${isMenuShown ? 'isActive' : ''}`}>
          <span className="hmu-label-text">{intl`Mon compte`}</span>
          <ArrowUpIcon className="hmu-label-arrowUpIcon" />
          <ArrowDownIcon className="hmu-label-arrowDownIcon" />
          <MenuIcon className="hmu-label-menuIcon" />
        </button>
        {isMenuShown && (
          <div className="hmu-list">
            <Link
              className="hmu-link"
              to="/account"
              onClick={handleToggleMenu}
            >{intl`Gestion du compte`}</Link>
            <Link
              className="hmu-link"
              to="/logout"
              onClick={handleToggleMenu}
            >{intl`Se déconnecter`}</Link>
          </div>
        )}
      </nav>
    </MainCSS>
  )
}

const headerMenuWrapper = props => {
  const context = {}
  return <HeaderMenu {...props} context={context} />
}

export default headerMenuWrapper
